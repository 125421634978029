import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewSkeleton from '../Skeletons/viewSkeleton';
import { CustomTreeProps } from './types';
import { CustomTreeName } from './const';
import { useData } from './hooks';
import { TitlesMargin } from '10.quickConnect.app/components/domain/Home/Inboxes/styles';
import { ListColumnContainer } from '10.quickConnect.app/components/domain/Home/styles';
import { TreeDataItem } from '30.quickConnect.Stores/helpers/type';
import { OverflowForDesktop } from '10.quickConnect.app/components/shared/LayOut/TopBar/style';

const CustomTree = <T extends TreeDataItem>({
  title,
  RenderTreeItem,
  store: { filter, setItemsTree, expandedNodes, setExpandedNodes, itemsTree },
  getName,
  cle,
}: CustomTreeProps<T>) => {
  const scrollTarget = useRef<HTMLElement>();
  const { t } = useTranslation('inboxes');

  const { handleToggle } = useData<T>(filter, getName, setItemsTree, setExpandedNodes);
  return (
    <Box data-cy={CustomTreeName} sx={ListColumnContainer} ref={scrollTarget}>
      <div id={`scrollToAnchor-${CustomTreeName}-${cle}`} />
      {title && (
        <Typography p={2} align="center" sx={TitlesMargin} variant={'h6'}>
          {title === '' ? cle : title}
        </Typography>
      )}
      {itemsTree ? (
        <TreeView
          expanded={expandedNodes}
          onNodeToggle={handleToggle}
          defaultExpanded={expandedNodes.length > 0 ? expandedNodes : ['root']}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            width: '100%',
            height: '100%',
            padding: {
              xs: '1em 1em 0em',
              sm: '1em 1em 0em',
              md: '1em',
              lg: '1em',
            },
            overflowY: OverflowForDesktop,
          }}
        >
          {itemsTree.length > 0 ? (
            itemsTree.map((td) => <RenderTreeItem key={td.id} {...td} cle={cle} />)
          ) : (
            <Typography align="center" p={1}>
              {t('no_elements').toString()}
            </Typography>
          )}
        </TreeView>
      ) : (
        <ViewSkeleton />
      )}
    </Box>
  );
};
export default observer(CustomTree);
