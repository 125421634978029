import React from 'react';
import { observer } from 'mobx-react';
import { Box, Card, CardContent, CardMedia, FormControl, Tooltip, Typography, useTheme } from '@mui/material';
import {
  ArticleOutlined,
  AudioFileOutlined,
  AttachFileOutlined,
  PictureAsPdfOutlined,
  VideoFileOutlined,
} from '@mui/icons-material';

import { FixedAttachmentQcFieldName } from './const';
import useData from './hooks';
import { CardMediaStyles, getCardContentStyles, FormControlStyles, TitleStyles } from './styles';

import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import {
  FixedAttachment,
  FixedAttachmentType,
} from '90.quickConnect.Models/models/fields/fieldDesc/attachmentField/fixedAttachmentDesc';

const FixedAttachmentQcField = ({ field, isReadOnly, labelSpan, setSelectedIndex }: ValueFieldsTypesProps) => {
  const { id, toolTip, value, fullPathId } = field as FixedAttachmentType;

  const fixedAttachment = (Array.isArray(value) ? value.at(0) : {}) as FixedAttachment;
  const { distantUri = '', fileName = '', type = '' } = fixedAttachment;

  const isImage = type.startsWith('image/');

  const nameWithId = `${FixedAttachmentQcFieldName}-${id}`;

  const { onOpenAttachment } = useData(fixedAttachment);

  const { breakpoints } = useTheme();

  return (
    <Box
      data-cy={`${nameWithId}-box`}
      onClick={isReadOnly ? undefined : () => setSelectedIndex(fullPathId)}
      sx={FieldMainBox(breakpoints)}
    >
      <Box sx={FlexRow}>
        {labelSpan}
        {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
      </Box>
      <FormControl data-cy={`${nameWithId}-control`} variant="outlined" sx={FormControlStyles}>
        <Card>
          <CardContent onClick={distantUri ? onOpenAttachment : undefined} sx={getCardContentStyles(distantUri)}>
            {isImage ? (
              <CardMedia
                component="img"
                height="110"
                width="150"
                image={distantUri}
                alt={fileName}
                onClick={() => window.open(distantUri)}
                sx={CardMediaStyles}
              />
            ) : (
              <Box display="flex" flex={1} alignItems="center" justifyContent="center">
                {type.includes('pdf') ? (
                  <PictureAsPdfOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
                ) : /word|powerpoint|excel/.test(type) ? (
                  <ArticleOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
                ) : type.includes('audio') ? (
                  <AudioFileOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
                ) : type.includes('video') ? (
                  <VideoFileOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
                ) : (
                  <AttachFileOutlined sx={{ fontSize: 60, opacity: 0.5 }} />
                )}
              </Box>
            )}
            <Tooltip title={fileName}>
              <Typography color="text.secondary" variant="body2" sx={TitleStyles}>
                {fileName}
              </Typography>
            </Tooltip>
          </CardContent>
        </Card>
      </FormControl>
    </Box>
  );
};

export default observer(FixedAttachmentQcField);
