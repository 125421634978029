import React from 'react';
import { observer } from 'mobx-react';
import { useTheme, Box, TextField, IconButton, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ClearIcon from '@mui/icons-material/Clear';
import { DateTimeQcFieldName } from './const';
import useData from './hooks';
import { ValueFieldsTypesProps } from '50.quickConnect.Fields/FieldsTypes/types';
import { DateTimeFieldType } from '90.quickConnect.Models/enums';
import QcTooltip from '50.quickConnect.Fields/QcTooltip';
import { FieldMainBox, FlexRow } from '50.quickConnect.Fields/styles';
import { DateTimeDesc } from '90.quickConnect.Models/models';
import { useStore } from '30.quickConnect.Stores';

const DateTimeQcField = ({
  field,
  updateDeclaration,
  labelSpan,
  setSelectedIndex,
  isReadOnly,
  flattenFields,
  labelClass,
}: ValueFieldsTypesProps) => {
  const dateTimeDesc = field as DateTimeDesc;
  const { toolTip, label, id, type, fullPathId } = dateTimeDesc;
  const nameWithId = `${DateTimeQcFieldName}-${id}`;
  const {
    t,
    localValue,
    locale,
    onChange,
    minPick,
    maxPick,
    onAccept,
    placeholderDateLocale,
    placeholderDateTimeLocale,
    handleOnChange,
  } = useData(dateTimeDesc, updateDeclaration, flattenFields);

  const {
    DeclarationStore: { verifRequiredField, requiredField },
  } = useStore();
  const { breakpoints } = useTheme();

  const labelBox = label ? (
    <Box sx={FlexRow}>
      {labelSpan}
      {toolTip && <QcTooltip parentId={id} tooltip={toolTip} />}
    </Box>
  ) : (
    <></>
  );

  return (
    <Box
      className={labelClass}
      id={`scrollToAnchor-${fullPathId}`}
      sx={FieldMainBox(breakpoints)}
      data-cy={nameWithId}
      onClick={() => (isReadOnly ? undefined : setSelectedIndex(fullPathId))}
    >
      {labelBox}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        {(type === DateTimeFieldType.Date || !type) && (
          <DatePicker
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={handleOnChange}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholderDateLocale,
                }}
                data-cy={`${nameWithId}-date`}
              />
            )}
            value={localValue}
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <Tooltip title={t('formlib_array_deleted_title').toString()} style={{ order: 1 }}>
                  <span>
                    <IconButton
                      data-cy={`${nameWithId}-clear`}
                      edge="end"
                      onClick={() => {
                        onAccept(null);
                      }}
                      disabled={isReadOnly}
                    >
                      <ClearIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
            }}
            InputAdornmentProps={{
              position: 'end',
              style: { order: 2, marginLeft: 0 },
            }}
            readOnly={isReadOnly}
            disabled={isReadOnly}
            minDate={minPick}
            maxDate={maxPick}
          />
        )}
        {type === DateTimeFieldType.DateTime && (
          <DateTimePicker
            inputRef={(input) => {
              if (input != null && verifRequiredField && requiredField?.fullPathId === fullPathId) {
                input.focus();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                data-cy={`${nameWithId}-dateTime`}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholderDateTimeLocale,
                }}
              />
            )}
            value={localValue}
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <Tooltip title={t('formlib_array_deleted_title').toString()} style={{ order: 1 }}>
                  <span>
                    <IconButton
                      data-cy={`${nameWithId}-clear`}
                      edge="end"
                      onClick={() => {
                        onAccept(null);
                      }}
                      disabled={isReadOnly}
                    >
                      <ClearIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ),
            }}
            InputAdornmentProps={{
              position: 'end',
              style: { order: 2, marginLeft: 0 },
            }}
            readOnly={isReadOnly}
            disabled={isReadOnly}
            minDateTime={minPick}
            maxDateTime={maxPick}
          />
        )}
      </LocalizationProvider>
    </Box>
  );
};
export default observer(DateTimeQcField);
