import styled from '@emotion/styled';
import { TreeItem } from '@mui/lab';

export const NoPaddingTreeItem = styled(TreeItem)`
  & > .MuiTreeItem-content {
    padding: 0 !important;
  }

  ${(props) => (props.theme as any).breakpoints.down('md')} {
    & > .MuiTreeItem-content > .MuiTreeItem-iconContainer svg {
      font-size: 32px;
    }
    & > .MuiTreeItem-group {
      margin-left: 5px;
    }
  }
  ${(props) => (props.theme as any).breakpoints.down('up')} {
    & > .MuiTreeItem-group {
      margin-left: 10px;
    }
  }
`;
