import React, { useMemo } from 'react';
import { TreeItem, TreeView } from '@mui/lab';
import { Business, ChevronRight, ExpandMore, KeyboardArrowDown } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { Box, Fade, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { OrganizationalUnitsTreeName } from './const';
import useData from './hooks';
import { OrganizationalUnitsMenuProps } from './types';
import { OrganizationalUnitViewer } from '90.quickConnect.Models/models/user/organizationalUnitViewer';
import { UserButtonAction } from '10.quickConnect.app/components/shared/LayOut/TopBar/RightItems/style';
import { useWindowSize } from '80.quickConnect.Core/hooks';

const OrganizationalUnitsMenu: React.FC<OrganizationalUnitsMenuProps> = ({
  isEditingCurrentDeclaration,
  editableDeclaration,
}: OrganizationalUnitsMenuProps) => {
  const {
    ouMenuOpen,
    ouMenuAnchorEl,
    isMultiOUenabled,
    currentOuCode,
    userOrganizationnalUnits,
    handleOuMenuClose,
    handleOuMenuClick,
    handleOuMenuMoveTo,
    reduceOrganizationnalUnits,
    getParentCodes,
    findOrganizationalUnitByCode,
  } = useData();

  const theme = useTheme();
  const { width } = useWindowSize();

  const hierarchicalOUs = useMemo(
    () => reduceOrganizationnalUnits(userOrganizationnalUnits),
    [userOrganizationnalUnits, reduceOrganizationnalUnits],
  );

  const expandedNodes = useMemo(() => {
    const foundOu = findOrganizationalUnitByCode(hierarchicalOUs, currentOuCode);
    if (foundOu) {
      return getParentCodes(foundOu, []);
    } else {
      return [];
    }
  }, [currentOuCode, findOrganizationalUnitByCode, getParentCodes, hierarchicalOUs]);

  const renderOrganizationalUnitTree = (nodes: OrganizationalUnitViewer[]) =>
    nodes.map((node) => (
      <TreeItem
        key={node.code}
        nodeId={node.code}
        label={
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleOuMenuMoveTo(node.code);
            }}
            selected={node.code === currentOuCode}
            sx={{ textDecoration: node.code === currentOuCode ? 'underline' : 'none' }}
          >
            <Typography variant="inherit">{node.name}</Typography>
          </MenuItem>
        }
      >
        {Array.isArray(node.children) ? renderOrganizationalUnitTree(node.children) : null}
      </TreeItem>
    ));

  return isMultiOUenabled ? (
    <Box>
      <UserButtonAction
        variant="text"
        color="lightThemeTextColor"
        disabled={isEditingCurrentDeclaration && editableDeclaration}
        data-cy={`${OrganizationalUnitsTreeName}-ou-btn`}
        id="fade-ou-button"
        aria-controls={ouMenuOpen ? 'ou-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={ouMenuOpen ? 'true' : undefined}
        onClick={handleOuMenuClick}
        endIcon={<KeyboardArrowDown />}
        sx={{ marginRight: '0.5vw' }}
        startIcon={width && width < theme.breakpoints.values.md && <Business />}
      >
        {width &&
          width > theme.breakpoints.values.md &&
          findOrganizationalUnitByCode(hierarchicalOUs, currentOuCode)?.name}
      </UserButtonAction>
      <Menu
        id="ou-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-ou-button',
        }}
        anchorEl={ouMenuAnchorEl}
        open={ouMenuOpen}
        onClose={() => handleOuMenuClose()}
        TransitionComponent={Fade}
      >
        <TreeView
          data-cy={`${OrganizationalUnitsTreeName}-ou-tree`}
          id="ou-tree"
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          defaultExpanded={expandedNodes}
        >
          {renderOrganizationalUnitTree(hierarchicalOUs)}
        </TreeView>
      </Menu>
    </Box>
  ) : null;
};

export default observer(OrganizationalUnitsMenu);
